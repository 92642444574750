import { useEffect, useMemo } from "react";
import FilterProps from "./FilterProps";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { dataTypeOperatorMap } from "../../../constants/customFilterDataType";
import * as filterOperators from "../../../constants/filterOperator";
import { groupingNameMap } from "../../../constants/filterGrouping";

import IconButton from "../../UI/IconButton";
import Toggle from "../../UI/Toggle";
import { AND, OR } from "../../../constants/customFilterType";
import Checkbox from "../../UI/Checkbox";

const DateFilter = (props: FilterProps) => {
    const parsedValue: { from: string, to: string } = useMemo(() => {
        const defaultValue = { from: '', to: '' };
        if (!props.value || props.value.indexOf('{') === -1) {
            return defaultValue
        }

        try {
            
            const valueJson = JSON.parse(props.value);
            if (!valueJson.hasOwnProperty('from')) {
                return defaultValue;
            }

            if (!valueJson.hasOwnProperty('to')) {
                return defaultValue;
            }

            return valueJson;
        } catch (e) {
            return defaultValue;
        }
    }, [props.value]);      
    
    useEffect(() => {
        if (props.showEndConjunction) {
            handleOnEndConjunctionChange(props.endConjunction);
        } else {
            handleOnEndConjunctionChange(undefined);
        }
    }, [props.showEndConjunction, props.endConjunction]);
    
    useEffect(() => {
        if (props.showStartConjunction) {
            handleOnStartConjunctionChange(props.startConjunction);
        } else {
            handleOnStartConjunctionChange(undefined);
        }
    }, [props.showStartConjunction, props.startConjunction]);

    const handleOnStartConjunctionChange = (conjunction?: string) => {
        props.onStartConjunctionChange(props.id, conjunction);
    };

    const handleOnEndConjunctionChange = (conjunction?: string) => {
        props.onEndConjunctionChange(props.id, conjunction);
    };

    const handleOnValueChange = (value: string, type?: 'from' | 'to') => {
        if (type) {
            props.onValueChange!(props.id, JSON.stringify({
                ...parsedValue,
                [type]: value
            }));
        } else {
            props.onValueChange!(props.id, value);
        }
    };

    const getInputs = () => {
        if (!props.operator) {
            return null;
        }

        switch (props.operator) {
            case filterOperators.EQUALS:
            case filterOperators.NOT_EQUALS:
            case filterOperators.GREATER_THAN:
            case filterOperators.GREATER_THAN_OR_EQUAL_TO:
            case filterOperators.LESS_THAN:
            case filterOperators.LESS_THEN_OR_EQUAL_TO:
                return <>
                    <Input id={`filter-date-equals-${props.id}`}
                        value={props.value}
                        type="date"
                        onChange={handleOnValueChange} />
                </>;
            case filterOperators.BETWEEN:
                return <div className="row align-items-center justify-content-between">
                    <div className="column-auto">
                        <Input id={`date-filter-from-${props.id}`}
                            value={parsedValue.from}
                            onChange={v => handleOnValueChange(v, 'from')}
                            type="date" />
                    </div>
                    <div className="column-auto">
                        and
                    </div>
                    <div className="column-auto">
                        <Input id={`date-filter-to-${props.id}`}
                            value={parsedValue.to}
                            onChange={v => handleOnValueChange(v, 'to')}
                            type="date" />
                    </div>
                </div>
            default:
                console.warn('Operator is unknown for date filter', props.operator);
                break;
        }

        return null;
    };

    const handleOnOperatorChange = (operator: string) => {
        props.onOperatorChange!(props.id, operator);
    };

    const handleOnDelete = () => {
        props.onDelete(props.id);
    };

    const handleOnSelectedChanged = (selected: boolean) => {
        props.onSelectedChange!(props.id, selected);
    };

    return <>
        {props.showStartConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnStartConjunctionChange}
            value={props.startConjunction} />}
            <div className="row align-items-center justify-content-between">
                <div className="column-auto">
                    <Checkbox id={`filter-selected-${props.id}`}
                        checked={props.selected}
                        label="Select to Group"
                        containerClassName="unboxed margin-top-0"
                        onChange={handleOnSelectedChanged} />
                </div>
                <div className="column-auto">
                    <IconButton id={`delete-filter-${props.id}`}
                        icon={<i className="ri-delete-bin-line"></i>}
                        onClick={handleOnDelete}
                        className="small flat" />
                </div>
            </div>
        <div className="row padding-3">
            <div className="column-auto text-bold">
                {groupingNameMap[props.grouping]}
            </div>
        </div>
        <div className="row justify-content-between align-items-center padding-3">
            <div className="column-3">
                {props.name}
            </div>
            <div className="column-3">
                <Select id={`filter-operation-${props.id}`} value={props.operator} onChange={handleOnOperatorChange}>
                    <option>Select an Operator</option>
                    {dataTypeOperatorMap[props.dataType!].map((m, i) => {
                        return <option key={i} value={m}>{filterOperators.operatorLabelMap[m]}</option>
                    })}
                </Select>
            </div>
            <div className="column-6">
                {getInputs()}
            </div>
        </div>
        {props.showEndConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnEndConjunctionChange}
            value={props.endConjunction} />}
    </>
};

export default DateFilter;