import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CUSTOM_FILTER } from "../../../constants/reportStep";
import getclassNames from "../../../utils/getClassNames";
import Button from "../../UI/Button";
import IconButton from "../../UI/IconButton";
import ReportStepProps from "./ReportStepProps";
import { AND, filters, LEFT_PARENTHESIS, RIGHT_PARENTHESIS } from "../../../constants/customFilterType";
import { useMemo, useState } from "react";
import './CustomFilter.css';
import CustomFilterType from "../../../models/CustomFilterType";
import * as uuid from 'uuid';
import CustomQuery from "../../../models/CustomQuery";
import { groupingNameMap, LOGICAL } from "../../../constants/filterGrouping";
import { dataTypeTofilterMap, filterTypeToFilterMap } from "../filters/filterComponentMap";

const CUSTOM_QUERY = 'custom_query';

const CustomFilter = (props: ReportStepProps) => {
    const [query, setQuery] = useState<CustomQuery>({ filters: [] });
    const [filtersHidden, setFiltersHidden] = useState<string[]>([]);
    const [draggingFilter, setDraggingFilter] = useState(false);

    const handleToggleExpanded = () => {
        props.onExpand(CUSTOM_FILTER, !props.expanded);
    };

    const handleOnNext = () => {
        props.onNext(CUSTOM_FILTER);
    };

    const handleOnDragStart = () => {
        setDraggingFilter(true);
    };

    const handleOnDragEnd = (result: any) => {
        setDraggingFilter(false);
        const { source, destination, draggableId } = result;
        // make sure there is a destination (picked up and dropped outside range)
        // They droppining into the same location in the fields
        // Tried to drop into controls area (no sorry)

        if (!destination ||
            (source.droppableId === '/queries' && destination.droppableId === '/queries' && destination.index === source.index) ||
            destination.droppableId === '/filters' ||
            (source.droppableId === '/filters' && destination.droppableId === '/trash')) {
            return;
        }

        if (source.droppableId === '/filters') {
            const [_, groupName, filterType] = (draggableId as string).split('/').filter(i => i !== '');

            const matchingGroup = groupedFilters.find(g => g.groupName === groupName);
            if (!matchingGroup) {
                console.warn('Unable to find group based on source item', groupName, draggableId);
                return;
            }

            const matchingFilter = matchingGroup.filters.find(f => f.type === filterType);
            if (!matchingFilter) {
                console.warn('Unable to find matching filter in group.', groupName, filterType);
                return;
            }

            if (query.filters.length === 0) {
                query.filters.push({
                    ...matchingFilter,
                    id: uuid.v4()
                });
            } else {
                query.filters.splice(destination.index, 0, {
                    ...matchingFilter,
                    id: uuid.v4()
                });
            }

            const newQuery = {
                filters: [
                    ...query.filters
                ]
            };
            setQuery(newQuery);
            handleOnChange(newQuery);

            // they are wanting to add a new field at index destination.index
        } else if (source.droppableId === '/queries') {
            const newFilters = [];

            if (destination.droppableId === '/trash') {
                for (let i = 0; i < query.filters.length; i++) {
                    if (i !== source.index) {
                        newFilters.push(query.filters[i]);
                    }
                }
            } else {
                const existingFilter = query.filters[source.index];

                for (let i = 0; i < query.filters.length; i++) {
                    const filter = query.filters[i];
                    if (destination.index === i) {
                        if (destination.index < source.index) {
                            newFilters.push(existingFilter);
                        }

                        newFilters.push(filter);

                        if (destination.index > source.index) {
                            newFilters.push(existingFilter);
                        }

                    } else if (filter.id !== existingFilter.id) {
                        newFilters.push(filter);
                    }
                }
            }

            const newQuery = {
                filters: newFilters
            };

            setQuery(newQuery);
            handleOnChange(newQuery);
        }
    };

    const handleHideShowGroups = (groupName: string) => {
        if (filtersHidden.includes(groupName)) {
            setFiltersHidden([
                ...filtersHidden.filter(f => f !== groupName)
            ])
        } else {
            setFiltersHidden([
                ...filtersHidden,
                groupName
            ])
        }
    };

    const getQueryFilterClass = (filter: CustomFilterType) => {
        if (filter.grouping !== LOGICAL) {
            return 'entry';
        }

        if (filter.type === LEFT_PARENTHESIS || filter.type === RIGHT_PARENTHESIS) {
            return 'grouping';
        }

        return 'logic';
    };

    const handleOnFilterValueChange = (id: string, value: string) => {
        const matchingFilter = query.filters.find(f => f.id === id);
        if (!matchingFilter) {
            return;
        }

        matchingFilter.value = value;

        const newQuery = {
            filters: [...query.filters]
        };

        setQuery(newQuery);
        handleOnChange(newQuery);
    };

    const handleOnChange = (newQuery: CustomQuery) => {
        props.onChange({
            step: CUSTOM_FILTER,
            values: [
                { name: CUSTOM_QUERY, value: JSON.stringify(newQuery) }
            ]
        });
    };

    const handleOnFilterSelectedChange = (id: string, selected: boolean) => {
        const matchingFilter = query.filters.find(f => f.id === id);
        if (!matchingFilter) {
            return;
        }

        matchingFilter.selected = selected;

        const newQuery = {
            filters: [...query.filters]
        };

        setQuery(newQuery);

        handleOnChange(newQuery);
    };

    const handleOnFilterEndConjunctionChange = (id: string, conjunction?: string) => {
        const matchingFilter = query.filters.find(f => f.id === id);
        if (!matchingFilter) {
            return;
        }

        matchingFilter.endConjunction = conjunction;

        const newQuery = {
            filters: [...query.filters]
        };

        setQuery(newQuery);

        handleOnChange(newQuery);
    };

    const handleOnFilterStartConjunctionChange = (id: string, conjunction?: string) => {
        const matchingFilter = query.filters.find(f => f.id === id);
        if (!matchingFilter) {
            return;
        }

        matchingFilter.startConjunction = conjunction;

        const newQuery = {
            filters: [...query.filters]
        };

        setQuery(newQuery);

        handleOnChange(newQuery);
    };

    const handleOnFilterOperatorChange = (id: string, operator: string) => {
        const matchingFilter = query.filters.find(f => f.id === id);
        if (!matchingFilter) {
            return;
        }

        matchingFilter.operator = operator;

        const newQuery = {
            filters: [...query.filters]
        };

        setQuery(newQuery);

        handleOnChange(newQuery);
    };

    const groupedFilters = useMemo(() => {
        const reportTypeFilters = filters.filter(f => f.reportTypes.includes(props.reportType) || f.reportTypes.length === 0);
        const grouping: { groupName: string, filters: CustomFilterType[] }[] = [];
        let index = 0;
        for (const f of reportTypeFilters) {
            let existingGrouping = grouping.find(g => g.groupName === f.grouping);
            if (!existingGrouping) {
                existingGrouping = {
                    groupName: f.grouping,
                    filters: []
                };
                grouping.push(existingGrouping);
            }
            f.index = index++;
            existingGrouping.filters.push(f);
        }

        return grouping;
    }, [props.reportType]);

    const handleOnGroupFilters = () => {
        const selectedFilters = query.filters.filter(f => f.selected);
        if (selectedFilters.length === 0) {
            return;
        }

        const newFilters = [];

        const groupedId = uuid.v4();
        for (const filter of query.filters) {
            filter.selected = false;

            if (selectedFilters[0] === filter) {
                newFilters.push({
                    id: uuid.v4(),
                    type: LEFT_PARENTHESIS,
                    grouping: LOGICAL,
                    reportTypes: [],
                    dataType: null,
                    isExtenalLookup: false,
                    name: '(',
                    groupedId
                });

                for (const selectedFilter of selectedFilters) {
                    selectedFilter.grouped = true;

                    newFilters.push(selectedFilter);
                }

                newFilters.push({
                    id: uuid.v4(),
                    type: RIGHT_PARENTHESIS,
                    grouping: LOGICAL,
                    reportTypes: [],
                    dataType: null,
                    isExtenalLookup: false,
                    name: ')',
                    groupedId
                });
            } else if (!selectedFilters.includes(filter)) {
                newFilters.push(filter);
            }
        }

        setQuery({
            filters: [...newFilters]
        });
    };

    const handleOnFilterDelete = (id: string) => {
        let newFilters = null;
        const matchingFilter = query.filters.find(f => f.id === id);
        if (matchingFilter?.groupedId) {
            newFilters = [...query.filters.filter(f => f.groupedId !== matchingFilter.groupedId)];
        } else {
            newFilters = [...query.filters.filter(f => f !== matchingFilter)];
        }

        if (newFilters.length >= 1) {
            const newerFilters = [];
            for (let i = 0; i < newFilters.length; i++) {
                const filter = newFilters[i];
                if (i !== (newFilters.length - 1)) {
                    if (filter.type !== LEFT_PARENTHESIS || newFilters[i + 1].type !== RIGHT_PARENTHESIS) {
                        newerFilters.push(filter);
                    } else {
                        i += 1; // skip next filter
                    }
                } else {
                    newerFilters.push(filter);
                }
            }

            setQuery({
                filters: newerFilters
            });
        } else {
            setQuery({
                filters: newFilters
            });
        }
    };

    let levelsDeep = 0;

    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
        <div className="report-step-header">
            <div className="row justify-content-between align-items-center">
                <div className="column-auto">
                    <h3>Build a filter</h3>
                </div>
                <div className="column-1">
                    <IconButton id="toggle-report-perspective-expansion"
                        className="small flat"
                        disabled={props.disabled}
                        icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                        onClick={handleToggleExpanded} />
                </div>
            </div>
        </div>
        <div className="report-step-body">
            <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
                <div className="row">
                    <div className="column-4">
                        <h4>Filters</h4>
                        <Droppable droppableId="/filters">
                            {(droppableProvided) =>
                                <div ref={droppableProvided.innerRef}>
                                    {groupedFilters.map((g) => {
                                        return <div key={g.groupName}>
                                            <h5>
                                                <IconButton id={`hide-show-controls-${g.groupName}`}
                                                    onClick={() => handleHideShowGroups(g.groupName)}
                                                    className="small flat margin-end-5"
                                                    icon={filtersHidden.includes(g.groupName) ?
                                                        <i className="ri-arrow-down-s-line"></i> :
                                                        <i className="ri-arrow-up-s-line"></i>} />
                                                {groupingNameMap[g.groupName]}
                                            </h5>
                                            {!filtersHidden.includes(g.groupName) && <div className="filter-options">{g.filters.map((f) => {
                                                return <Draggable key={f.name} draggableId={`/filters/${g.groupName}/${f.type}`} index={f.index!}>
                                                    {(providedDraggable) =>
                                                    (

                                                        <div className="query-filter" ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}>{f.name}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })}</div>}

                                        </div>

                                    })}
                                    {droppableProvided.placeholder}
                                </div>
                            }
                        </Droppable>
                    </div>
                    <div className="column">
                        <h4>Query</h4>
                        {query.filters.filter(f => f.selected).length > 1 && <div className="selected-filter-actions">
                            <Button id="group-filters"
                                onClick={handleOnGroupFilters}
                                text="Group Filters" />
                        </div>}
                        <Droppable droppableId="/queries" >
                            {(droppableProvided, snapshot) =>
                                <div className={getclassNames('query-container drop-container', query.filters.length === 0 ? 'empty' : '', snapshot.isDraggingOver ? 'dragging-over' : '')} ref={droppableProvided.innerRef}>
                                    {query.filters.length === 0 && <div className="draggable-area-directions">Drag a filter over to begin building your query</div>}
                                    {query.filters.map((g, i) => {
                                        return <Draggable draggableId={`/queries/${g.id}`} index={i} key={`/queries/${g.id}`}>
                                            {(providedDraggable) => {
                                                if (g.grouping === LOGICAL) {
                                                    let currentLevels = 0;
                                                    if (g.type === LEFT_PARENTHESIS) {
                                                        levelsDeep++;
                                                        currentLevels = levelsDeep - 1;
                                                    } else if (g.type === RIGHT_PARENTHESIS) {
                                                        levelsDeep--;
                                                        currentLevels = levelsDeep;
                                                    }

                                                    const groupingStyles: any = {};

                                                    if (providedDraggable.draggableProps.style?.transform || providedDraggable.draggableProps.style?.transition) {
                                                        for (const s in providedDraggable.draggableProps.style) {
                                                            if (providedDraggable.draggableProps.style.hasOwnProperty(s)) {
                                                                groupingStyles[s] = (providedDraggable.draggableProps.style as any)[s];
                                                            }
                                                        }
                                                    } else if (!draggingFilter) {
                                                        groupingStyles['marginLeft'] = `${currentLevels * 20}px`;
                                                    }

                                                    return <div ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps} style={groupingStyles} className={getclassNames('query-filter', getQueryFilterClass(g))}>
                                                        <div className="row align-items-center justify-content-between">
                                                            <div className="column-auto">
                                                                {g.name}
                                                            </div>
                                                            <div className="column-auto">
                                                                <IconButton id={`delete-filter-${g.id}`}
                                                                    icon={<i className="ri-delete-bin-line"></i>}
                                                                    onClick={() => handleOnFilterDelete(g.id || '')}
                                                                    className="small flat" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                let FilterComponent = null;
                                                if (g.dataType) {
                                                    FilterComponent = dataTypeTofilterMap[g.dataType];
                                                } else {
                                                    FilterComponent = filterTypeToFilterMap[g.type];
                                                }

                                                const showStartConjunction = query.filters.length > 1 && query.filters[i - 1]?.type === RIGHT_PARENTHESIS && (i - 2) >= 0;
                                                const showEndConjunction = query.filters.length !== 1 && query.filters[i + 1]?.type !== RIGHT_PARENTHESIS && i !== (query.filters.length - 1);
                                                //const showEndConjunction = query.filters.length !== 1 && i !== (query.filters.length - 1);

                                                const filterStyles: any = {};

                                                // this means it's being dragged
                                                if (providedDraggable.draggableProps.style?.transform || providedDraggable.draggableProps.style?.transition) {
                                                    for (const s in providedDraggable.draggableProps.style) {
                                                        if (providedDraggable.draggableProps.style.hasOwnProperty(s)) {
                                                            filterStyles[s] = (providedDraggable.draggableProps.style as any)[s];
                                                        }
                                                    }
                                                } else if (!draggingFilter) {
                                                    // if it's not being dragged then apply the 
                                                    filterStyles['marginLeft'] = `${levelsDeep * 20}px`;
                                                }

                                                return <div ref={providedDraggable.innerRef}
                                                    {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps} style={filterStyles} className={getclassNames('query-filter', getQueryFilterClass(g))}>
                                                    {FilterComponent && <FilterComponent id={g.id || ''}
                                                        value={g.value || ''}
                                                        name={g.name}
                                                        grouping={g.grouping}
                                                        dataType={g.dataType || ''}
                                                        startConjunction={showStartConjunction ? (g.startConjunction || AND) : ''}
                                                        endConjunction={showEndConjunction ? (g.endConjunction || AND) : ''}
                                                        grouped={g.grouped || false}
                                                        allStepValues={props.allStepValues}
                                                        groupedClassName={g.groupedClassName}
                                                        selected={g.selected || false}
                                                        showStartConjunction={showStartConjunction}
                                                        showEndConjunction={showEndConjunction}
                                                        onSelectedChange={handleOnFilterSelectedChange}
                                                        onStartConjunctionChange={handleOnFilterStartConjunctionChange}
                                                        onEndConjunctionChange={handleOnFilterEndConjunctionChange}
                                                        onDelete={handleOnFilterDelete}
                                                        onOperatorChange={handleOnFilterOperatorChange}
                                                        onValueChange={handleOnFilterValueChange}
                                                        operator={g.operator || ''}
                                                        type={g.type}
                                                        possibleValues={g.possibleValues} />}
                                                </div>
                                            }}
                                        </Draggable>
                                    })}
                                    {droppableProvided.placeholder}
                                </div>
                            }
                        </Droppable>
                        {query.filters.length > 0 && <div className="row justify-content-center">
                            <div className="column-auto">
                                <Droppable droppableId="/trash">
                                    {(droppableProvided, snapshot) =>
                                        <div className={getclassNames('trash-container', snapshot.isDraggingOver ? 'dragging-over' : '')} ref={droppableProvided.innerRef}>
                                            {snapshot.isDraggingOver ? <i className="ri-delete-bin-2-line"></i> : <i className="ri-delete-bin-line"></i>}
                                        </div>
                                    }
                                </Droppable>
                            </div>
                        </div>}
                    </div>
                </div>
            </DragDropContext>
            <div className="row justify-content-end margin-vertical-3">
                <div className="column-auto">
                    <Button id="report-type-next"
                        text="Next"
                        icon={<i className="ri-arrow-right-s-line"></i>}
                        iconLocation="end"
                        className="secondary"
                        disabled={props.disabled}
                        onClick={handleOnNext} />
                </div>
            </div>
        </div>
    </div>);
}

export default CustomFilter;