import { useEffect, useMemo, useState } from "react";
import FilterProps from "./FilterProps";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { fieldTypeOperatorMap } from "../../../constants/customFilterDataType";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import Loader from "../../UI/Loader";
import { operatorLabelMap, UNANSWERED } from "../../../constants/filterOperator";
import { groupingNameMap } from "../../../constants/filterGrouping";
import IconButton from "../../UI/IconButton";
import FormField from "../../../models/serviceModels/FormField";
import { getForm } from "../../../services/formsService";
import * as filterOperators from "../../../constants/filterOperator";
import { ACTIVITY_DATE_ID, BREAK_LINE_ID, CLIENT_ID, DATE_ID, HEADER_ID } from "../../../constants/fieldTypes";
import Toggle from "../../UI/Toggle";
import { AND, OR } from "../../../constants/customFilterType";
import Checkbox from "../../UI/Checkbox";
import { FORM_SELECTION } from "../../../constants/reportStep";

const FormFieldFilter = (props: FilterProps) => {
    const [fields, setFields] = useState<FormField[]>([]);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        const formSelectionStepValues: { name: string, value: string}[] = props.allStepValues[FORM_SELECTION];
        if (!formSelectionStepValues) {
            console.error('Form selection step not found.  Unable to find which form to load');
            setApiError('Failed to load fields');
            return;
        }

        const formIdValue = formSelectionStepValues.find(v => v.name === 'form_id');
        if (!formIdValue?.value) {
            console.error('Form Id not found. Unable to load form');
            setApiError('Failed to load fields');
            return;
        }

        const versionIdValue = formSelectionStepValues.find(v => v.name === 'version_id');
        if (!versionIdValue?.value) {
            console.error('Version Id not found. Unable to load form');
            setApiError('Failed to load fields');
            return;
        }
        
        setLoading(true);
        
        getForm(formIdValue.value, versionIdValue.value).then(f => {
            setFields(f.fields.filter(f => f.fieldTypeId !== BREAK_LINE_ID && f.fieldTypeId !== HEADER_ID && f.fieldTypeId !== CLIENT_ID));
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (props.showEndConjunction) {
            handleOnEndConjunctionChange(props.endConjunction);
        } else {
            handleOnEndConjunctionChange(undefined);
        }
    }, [props.showEndConjunction, props.endConjunction]);

    useEffect(() => {
        if (props.showStartConjunction) {
            handleOnStartConjunctionChange(props.startConjunction);
        } else {
            handleOnStartConjunctionChange(undefined);
        }
    }, [props.showStartConjunction, props.startConjunction]);

    const handleOnStartConjunctionChange = (conjunction?: string) => {
        props.onStartConjunctionChange(props.id, conjunction);
    };

    const handleOnEndConjunctionChange = (conjunction?: string) => {
        props.onEndConjunctionChange(props.id, conjunction);
    };

    const handleOnSelectedChanged = (selected: boolean) => {
        props.onSelectedChange!(props.id, selected);
    };

    const getParsedValue = () => {
        const defaultValue = {
            field: '',
            value: '',
            from: '',
            to: '',
            fieldTypeId: ''
        };

        if (!props.value || props.value.indexOf('{') === -1) {
            return defaultValue;
        }

        try {
            const parsed = JSON.parse(props.value);
            return parsed;
        } catch {
            return defaultValue;
        }
    };

    const handleOnOperatorChange = (operator: string) => {
        if (operator === UNANSWERED) {
            const parsedValue = getParsedValue();
            props.onValueChange!(JSON.stringify({
                field: parsedValue.field,
                value: ''
            }), props.id);
        }
        props.onOperatorChange!(props.id, operator);
    };

    const handleOnFieldChange = (value: string) => {
        const existingValue = getParsedValue();
        props.onValueChange!(props.id, JSON.stringify({
            field: value,
            value: existingValue.value || ''
        }));
    };

    const handleOnValueChange = (value: string, property?: string) => {
        const existingValue = getParsedValue();
        if (property) {
            existingValue[property] = value;
        } else {
            existingValue['value'] = value;
        }
        
        props.onValueChange!(props.id, JSON.stringify(existingValue));
    };

    const handleOnDelete = () => {
        props.onDelete(props.id);
    };

    const getInputs = (field: FormField) => {
        if (!props.operator) {
            return null;
        }

        switch (props.operator) {
            case filterOperators.EQUALS:
            case filterOperators.NOT_EQUALS:
            case filterOperators.GREATER_THAN:
            case filterOperators.GREATER_THAN_OR_EQUAL_TO:
            case filterOperators.LESS_THAN:
            case filterOperators.LESS_THEN_OR_EQUAL_TO:
                if (field.options) {
                    return <Select id={`filter-select-${props.id}`} value={parsedValue.value} onChange={handleOnValueChange}>
                    <option>Select a Value</option>
                    {field.options.map(pv => {
                        return <option key={pv.value} value={pv.value}>{pv.label}</option>
                    })}
                </Select>;
                }

                if (field.fieldTypeId === DATE_ID || field.fieldTypeId === ACTIVITY_DATE_ID) {
                    return <>
                        <Input id={`filter-text-equals-${props.id}`}
                            value={parsedValue.value}
                            type="date"
                            onChange={handleOnValueChange} />
                    </>;
                }
                return <>
                    <Input id={`filter-text-equals-${props.id}`}
                        value={parsedValue.value}
                        onChange={handleOnValueChange} />
                </>;
            case filterOperators.BETWEEN:
                return <div className="row align-items-center justify-content-between">
                    <div className="column-auto">
                        <Input id={`date-filter-from-${props.id}`}
                            value={parsedValue.from}
                            onChange={v => handleOnValueChange(v, 'from')}
                            type="date" />
                    </div>
                    <div className="column-auto">
                        and
                    </div>
                    <div className="column-auto">
                        <Input id={`date-filter-to-${props.id}`}
                            value={parsedValue.to}
                            onChange={v => handleOnValueChange(v, 'to')}
                            type="date" />
                    </div>
                </div>
            default:
                console.warn('Operator is unknown for date filter', props.operator);
                break;
        }

        return null;
    };


    if (loading) {
        return <Loader />
    }

    const parsedValue = getParsedValue();
    const currentField: FormField = parsedValue.field && fields.find(f => f.id === parsedValue.field);

    return <>
        {props.showStartConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnStartConjunctionChange}
            value={props.startConjunction} />}
        <ApiErrorMessages error={apiError} />
        <div className="row align-items-center justify-content-between">
            <div className="column-auto">
                <Checkbox id={`filter-selected-${props.id}`}
                    checked={props.selected}
                        label="Select to Group"
                    containerClassName="unboxed margin-top-0"
                    onChange={handleOnSelectedChanged} />
            </div>
            <div className="column-auto">
                <IconButton id={`delete-filter-${props.id}`}
                    icon={<i className="ri-delete-bin-line"></i>}
                    onClick={handleOnDelete}
                    className="small flat" />
            </div>
        </div>
        <div className="row padding-3">
            <div className="column-auto text-bold">
                {groupingNameMap[props.grouping]} - Form Fields
            </div>
        </div>
        <div className="row justify-content-between align-items-center padding-3">
            <div className="column-3">
                <Select id={`filter-field-${props.id}`} value={parsedValue.field} onChange={handleOnFieldChange}>
                    <option>Select a Field</option>
                    {fields.map(f => {
                        return <option key={f.id} value={f.id}>{f.label}</option>
                    })}
                </Select>
            </div>
            <div className="column-3">
                <Select id={`filter-operation-${props.id}`} value={props.operator} onChange={handleOnOperatorChange}>
                    <option>Select an Operation</option>
                    {currentField && fieldTypeOperatorMap[currentField.fieldTypeId] && fieldTypeOperatorMap[currentField.fieldTypeId].map((m, i) => {
                        return <option key={i} value={m}>{operatorLabelMap[m]}</option>
                    })}
                </Select>
            </div>
            <div className="column-6">
                {getInputs(currentField)}
            </div>
        </div>
        {props.showEndConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnEndConjunctionChange}
            value={props.endConjunction} />}
    </>
};

export default FormFieldFilter;