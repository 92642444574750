import { useEffect, useMemo, useState } from "react";
import FilterProps from "./FilterProps";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { groupingNameMap } from "../../../constants/filterGrouping";
import IconButton from "../../UI/IconButton";
import { searchPrograms } from "../../../services/programsService";
import Program from "../../../models/serviceModels/Program";
import Loader from "../../UI/Loader";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import Toggle from "../../UI/Toggle";
import { AND, OR } from "../../../constants/customFilterType";
import Checkbox from "../../UI/Checkbox";
import { filterTypeOperatorMap } from "../../../constants/customFilterDataType";
import * as filterOperators from "../../../constants/filterOperator";

const AgeFilter = (props: FilterProps) => {
    useEffect(() => {
        if (props.showEndConjunction) {
            handleOnEndConjunctionChange(props.endConjunction);
        } else {
            handleOnEndConjunctionChange(undefined);
        }
    }, [props.showEndConjunction, props.endConjunction]);

    useEffect(() => {
        if (props.showStartConjunction) {
            handleOnStartConjunctionChange(props.startConjunction);
        } else {
            handleOnStartConjunctionChange(undefined);
        }
    }, [props.showStartConjunction, props.startConjunction]);

    const handleOnStartConjunctionChange = (conjunction?: string) => {
        props.onStartConjunctionChange(props.id, conjunction);
    };

    const handleOnEndConjunctionChange = (conjunction?: string) => {
        props.onEndConjunctionChange(props.id, conjunction);
    };

    const parsedValue: { from: string, to: string, value: string, asOfDate: string } = useMemo(() => {
        const defaultValue = { from: '', to: '', value: '', asOfDate: '' };
        if (!props.value || props.value.indexOf('{') === -1) {
            return defaultValue
        }

        try {

            const valueJson = JSON.parse(props.value);
            if (!valueJson.hasOwnProperty('from')) {
                return defaultValue;
            }

            if (!valueJson.hasOwnProperty('to')) {
                return defaultValue;
            }

            if (!valueJson.hasOwnProperty('value')) {
                return defaultValue;
            }

            if (!valueJson.hasOwnProperty('asOfDate')) {
                return defaultValue;
            }

            return valueJson;
        } catch (e) {
            return defaultValue;
        }
    }, [props.value]);

    const handleOnValueChange = (value: string, type: 'from' | 'to' | 'value' | 'asOfDate') => {
        props.onValueChange!(props.id, JSON.stringify({
            ...parsedValue,
            [type]: value
        }));
    };

    const handleOnDelete = () => {
        props.onDelete(props.id);
    };

    const handleOnSelectedChanged = (selected: boolean) => {
        props.onSelectedChange!(props.id, selected);
    };

    const handleOnOperatorChange = (value: string) => {
        props.onOperatorChange!(props.id, value);
    };

    const getInputs = () => {
        if (!props.operator) {
            return null;
        }

        switch (props.operator) {
            case filterOperators.EQUALS:
            case filterOperators.NOT_EQUALS:
            case filterOperators.GREATER_THAN:
            case filterOperators.GREATER_THAN_OR_EQUAL_TO:
            case filterOperators.LESS_THAN:
            case filterOperators.LESS_THEN_OR_EQUAL_TO:
                return <>
                    <Input id={`filter-date-equals-${props.id}`}
                        value={parsedValue.value}
                        type="number"
                        onChange={(v) => handleOnValueChange(v, 'value')} />
                </>;
            case filterOperators.BETWEEN:
                return <div className="row align-items-center justify-content-between">
                    <div className="column-auto">
                        <Input id={`date-filter-from-${props.id}`}
                            value={parsedValue.from}
                            onChange={v => handleOnValueChange(v, 'from')}
                            type="number" />
                    </div>
                    <div className="column-auto">
                        and
                    </div>
                    <div className="column-auto">
                        <Input id={`date-filter-to-${props.id}`}
                            value={parsedValue.to}
                            onChange={v => handleOnValueChange(v, 'to')}
                            type="number" />
                    </div>
                </div>
            default:
                console.warn('Operator is unknown for date filter', props.operator);
                break;
        }

        return null;
    };

    return <>
        {props.showStartConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnStartConjunctionChange}
            value={props.startConjunction} />}
        <div className="row align-items-center justify-content-between">
            <div className="column-auto">
                <Checkbox id={`filter-selected-${props.id}`}
                    checked={props.selected}
                    label="Select to Group"
                    containerClassName="unboxed margin-top-0"
                    onChange={handleOnSelectedChanged} />
            </div>
            <div className="column-auto">
                <IconButton id={`delete-filter-${props.id}`}
                    icon={<i className="ri-delete-bin-line"></i>}
                    onClick={handleOnDelete}
                    className="small flat" />
            </div>
        </div>
        <div className="row padding-3">
            <div className="column-auto text-bold">
                {groupingNameMap[props.grouping]}
            </div>
        </div>
        <div className="row justify-content-between align-items-center padding-3">
            <div className="column-3">
                {props.name}
            </div>
            <div className="column">
                <div className="row align-items-center">
                    <div className="column-3">
                        <Select id={`filter-operation-${props.id}`} value={props.operator} onChange={handleOnOperatorChange}>
                            <option>Select an Operator</option>
                            {filterTypeOperatorMap[props.type].map((m, i) => {
                                return <option key={i} value={m}>{filterOperators.operatorLabelMap[m]}</option>
                            })}
                        </Select>
                    </div>
                    <div className="column-9">
                        {getInputs()}
                    </div>
                </div>
                <div className="row margin-top-3">
                    <div className="column-3">
                        <Input id="as-of-date"
                            label="As of Date"
                            value={parsedValue.asOfDate}
                            type="date"
                            onChange={(v) => handleOnValueChange(v, 'asOfDate')} />
                    </div>
                </div>
            </div>
        </div>
        {props.showEndConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnEndConjunctionChange}
            value={props.endConjunction} />}
    </>
};

export default AgeFilter;